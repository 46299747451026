<!-- 新闻中心 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text1 }}</h3>
      </div>
    </div>
    <div class="content">
      <div class="content_top">
        <ul class="list">
          <li v-for="(item, i) in articleList" :key="i">
            <div class="li_box">
              <h3>{{ item.title }}</h3>
              <p>
                {{ item.short_content }}
              </p>
              <div>
                <p>{{ item.date }}</p>
                <router-link :to="{ path: '/New/NewList', query: { id: item.id } }">{{ text3 }}</router-link>
              </div>
            </div>
            <img :src="item.img" :alt="item.title" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
const dataUrl = "https://www.futuregenbiopharm.com/manage/public/index.php/api/";
import { mapState } from "vuex";
const axios = require("axios");

// 获取新闻列表
function getArticleList(search, lang) {
  axios({
    url: `${dataUrl}Index/newList`,
    method: "post",
    data: `search=${search}&lang=${lang}&page=1&num=50`,
  })
    .then(res => {
      this.articleList = res.data.data.list;
    })
    .catch(err => {
      console.log(err);
    });
}
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "新闻中心",
          text2: "媒体报道",
          text3: "详情",
          articleList: [],
          getArticleList,
        };
        break;
      case "en":
        return {
          text1: "Media",
          text2: "media coverage",
          text3: "Details",
          articleList: [],
          getArticleList,
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },

  mounted() {
    this.$nextTick(function () {
      if (language == "cn") {
        this.getArticleList("", 1);
      } else {
        this.getArticleList("", 2);
      }
    });
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/header.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.content {
  width: 100%;
  .content_nav {
    height: 130px;
    text-align: center;
    line-height: 130px;
    background-color: rgba(204, 204, 204, 0.2);
    a {
      position: relative;
      height: 130px;
      color: #333;
      font-size: 22px;
      margin-right: 80px;
      font-weight: 600;
      opacity: 0.7;
      -webkit-tap-highlight-color: transparent;
    }
    :nth-child(1) {
      color: #c00000;
      padding-bottom: 50px;
    }
    .line_red::after {
      position: absolute;
      top: 100%;
      left: 0%;
      content: "";
      width: 79px;
      height: 2px;
      opacity: 0.6;
      background-color: #c00000;
    }
  }
  .content_top {
    width: 100%;
    .list {
      width: 100%;
      max-width: 1200px;
      margin: 150px auto;
      li {
        margin: 4vh 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 350px;
          height: 230px;
          object-fit: cover;
        }
        .li_box {
          width: 700px;
          h3 {
            color: #4a4a4a;
            font-size: 22px;
            margin: 2vh 0;
          }
          p {
            font-size: 16px;
            color: #999999;
            line-height: 1.6;
          }
          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 2vh 0;
            a {
              height: 22px;
              color: #203864;
              font-weight: bold;
              padding-right: 30px;
              background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/xq.png") no-repeat;
              background-position: right;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .content {
    .content_top {
      .list {
        margin: 0 auto;
        li {
          flex-direction: column;
          .li_box {
            width: 90vw;
            h3 {
              font-size: 20px;
            }
          }
          img {
            display: none;
          }
        }
      }
    }
  }
}
</style>
